<template>
    <main class="motor-journey-main flow-journey">
        <div class="container flow-container">
            <div class="flow-left-column">
                <div class="top-header">
                    <router-link to="/">
                        <div class="logo">
                            <img class="site-logo-meCompare" src="../../assets/images/logo/me-logo.svg">
                        </div>
                    </router-link>
                </div>

                <div class="flow-option-back">
                    <ul class="flow-option-back-ul">
                        <!-- <li class="flow-option-back-li" @click="back">
                            <i class="pi pi-arrow-left"></i>
                            Back
                        </li> -->
                    </ul>
                </div>
                <div class="flow-option-list">
                    <ul class="flow-option-list-ul">
                        <li class="flow-option-list-li"
                            v-bind:class="(user_motor_journey.step == 'make') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>1</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Vehicle Brand</p>
                                    <h4 class="flow-type-title">{{ user_motor_journey.make != null ?
                                        user_motor_journey.make.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_motor_journey.step == 'model') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>2</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Vehicle Model</p>
                                    <h4 class="flow-type-title">{{ user_motor_journey.model != null ?
                                        user_motor_journey.model.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_motor_journey.step == 'year') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>3</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Model Year</p>
                                    <h4 class="flow-type-title">{{ user_motor_journey.year != null ?
                                        user_motor_journey.year.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_motor_journey.step == 'specification') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>4</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Specification</p>
                                    <h4 class="flow-type-title">{{ user_motor_journey.specification != null ?
                                        user_motor_journey.specification.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_motor_journey.step == 'vehicle_value') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>5</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Vehicle Price</p>
                                    <h4 class="flow-type-title">{{ user_motor_journey.vehicle_value != null ?
                                        'AED '+number_with_commas(user_motor_journey.vehicle_value) : 'Not Selected' }}
                                    </h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_motor_journey.step == 'city') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>6</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">City</p>
                                    <h4 class="flow-type-title">{{ user_motor_journey.uae_city != null ?
                                        user_motor_journey.uae_city.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_motor_journey.step == 'insurance_type') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>7</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Insurance Type</p>
                                    <h4 class="flow-type-title">{{ user_motor_journey.insurance_type != null ?
                                        user_motor_journey.insurance_type.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_motor_journey.step == 'insurance_start_date') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>8</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Insurance Start Date</p>
                                    <h4 class="flow-type-title">{{ user_motor_journey.insurance_start_date_label != null
                                        ? user_motor_journey.insurance_start_date_label : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="flow-right-column">
                <div class="top-header">
                    <header class="header-container">
                        <div class="container">
                            <div class="grid header-grid m-0">
                                <div class="header-left">
                                    <router-link to="/" class="journey-header-logo">
                                        <div class="logo">
                                            <img class="site-logo-spaceDesign" src="../../assets/images/logo/logo.png">
                                        </div>
                                    </router-link>
                                </div>
                                <div class="header-right header-right-desktop">
                                    <ul class="header-left-menuUl header-left-menuUlFirst">

                                        <li
                                            class="header-left-menuItems header-left-menuItemsServices menuItemsCompany">
                                            <p>
                                                <img src="../../assets/images/Icons/headphone-icon.svg">
                                                Support
                                                <i class="pi pi-angle-down"
                                                    style="font-size: 0.8rem;padding-left: 0.1rem;"></i>
                                            </p>

                                            <div class="mega-box">
                                                <div class="content">
                                                    <div class="grid w-full">
                                                        <div class="col-12 md:col-12 lg:col-12">
                                                            <router-link to="/">
                                                                <div class="mega-menu-list">
                                                                    <div class="mega-menu-list-info">
                                                                        <h4 class="mega-menu-title">FAQ</h4>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </div>

                                                        <div class="col-12 md:col-12 lg:col-12">
                                                            <router-link to="/">
                                                                <div class="mega-menu-list border-0">
                                                                    <div class="mega-menu-list-info">
                                                                        <h4 class="mega-menu-title">Contact Us</h4>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                    <ul class="header-left-menuUl header-left-menuUlBtn">
                                        <li
                                            class="header-left-menuItems header-left-menuItemsServices language-switcher">
                                            <selectButton-prime v-model="selected_language" :options="language_options"
                                                aria-labelledby="basic" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div class="flow-steps-content-container">
                        <div class="progress-bar-container">
                            <div class="progress-head">
                                <p class="progress-bar-label back-btn-flow" @click="back">
                                    <i class="pi pi-arrow-left"></i>
                                    Back
                                </p>
                                <p class="progress-bar-label">
                                    {{user_motor_journey.question_number}}/{{user_motor_journey.total_questions}}
                                    Questions</p>
                            </div>
                            <progressBar-prime :value="user_motor_journey.progress_bar_percentage"></progressBar-prime>
                        </div>

                        <!-- Flow Vehicle Brand Start -->
                        <div class="step-container first-flow" v-if="user_motor_journey.step == 'make'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Select your vehicle brand</h2>

                                <div class="search-box-field">
                                    <IconField-prime iconPosition="right">
                                        <InputIcon-prime class="pi pi-search"> </InputIcon-prime>
                                        <inputText-prime @keyup="searchMakes" v-model="searchmake"
                                            placeholder="Search your brand" />
                                    </IconField-prime>
                                </div>

                                <ScrollPanel-prime class="content-scroll-panel mt-0" v-if="makes == null">
                                    <div class="journey-loaders-grid">
                                        <Skeleton-prime class="mb-2" v-for="index in 8" :key="index"></Skeleton-prime>
                                    </div>
                                </ScrollPanel-prime>

                                <ScrollPanel-prime class="content-scroll-panel" v-if="makes!= null && makes.length > 0">
                                    <div class="brand-box-container">
                                        <div class="brand-box-item makes-list" v-for="item in makes" :key="item" @click="set_value(item, 'make')">
                                            <div class="vehicle-brand-logo">
                                                <img :src="item.image">
                                            </div>
                                            <h4 class=" vehicle-brand-name">{{ item.name }}</h4>
                                        </div>
                                    </div>
                                </ScrollPanel-prime>
                            </div>
                        </div>
                        <!-- / Flow Vehicle Brand End -->

                        <!-- Flow Vehicle Model Start -->
                        <div class="step-container second-flow" v-if="user_motor_journey.step == 'model'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Select your vehicle model</h2>

                                <div class="search-box-field">
                                    <IconField-prime iconPosition="right">
                                        <InputIcon-prime class="pi pi-search"> </InputIcon-prime>
                                        <inputText-prime @keyup="searchModels" v-model="searchmodel" placeholder="Search vehicle model" />
                                    </IconField-prime>
                                </div>

                                <ScrollPanel-prime class="content-scroll-panel mt-0" v-if="models == null">
                                    <div class="journey-loaders-list">
                                        <Skeleton-prime class="mb-2" v-for="index in 3" :key="index"></Skeleton-prime>
                                    </div>
                                </ScrollPanel-prime>

                                <ScrollPanel-prime class="content-scroll-panel mt-0" v-if="models!= null && models.length > 0">
                                    <div class="select-list-item models-list" v-for="item in models" :key="item"
                                        @click="set_value(item, 'model')">
                                        <div class="select-list-item-box mb-0">
                                            <h2 class="select-list-item-name">{{ item.name }}</h2>
                                        </div>
                                    </div>
                                </ScrollPanel-prime>
                            </div>
                        </div>
                        <!-- / Flow Vehicle Model End -->

                        <!-- Flow Vehicle Model Year Start -->
                        <div class="step-container third-flow" v-if="user_motor_journey.step == 'year'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Select the model year of your vehicle</h2>

                                <ScrollPanel-prime class="content-scroll-panel mt-0" v-if="years == null">
                                    <div class="journey-loaders-list">
                                        <Skeleton-prime class="mb-2" v-for="index in 4" :key="index"></Skeleton-prime>
                                    </div>
                                </ScrollPanel-prime>

                                <ScrollPanel-prime class="content-scroll-panel mt-2" v-if="years!= null && years.length > 0">
                                    <div class="select-list-item" v-for="item in years" :key="item"
                                        @click="set_value(item, 'year')">
                                        <div class="select-list-item-box mb-0">
                                            <h2 class="select-list-item-name">{{ item.name }}</h2>
                                        </div>
                                    </div>
                                </ScrollPanel-prime>
                            </div>
                        </div>
                        <!-- / Flow Vehicle Model Year End -->

                        <!-- Flow Vehicle Specification Start -->
                        <div class="step-container second-flow vehicle-specification-flow" v-if="user_motor_journey.step == 'specification'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Select your vehicle Specification</h2>

                                <ScrollPanel-prime class="content-scroll-panel mt-0" v-if="specifications == null">
                                    <div class="journey-loaders-list">
                                        <Skeleton-prime class="mb-2" v-for="index in 4" :key="index"></Skeleton-prime>
                                    </div>
                                </ScrollPanel-prime>

                                <ScrollPanel-prime class="content-scroll-panel mt-2" v-if="specifications!= null && specifications.length > 0">
                                    <div class="select-list-item" v-for="item in specifications" :key="item"
                                        @click="set_value(item, 'specification')">
                                        <div class="select-list-item-box mb-0">
                                            <h2 class="select-list-item-name">{{ item.name }}</h2>
                                        </div>
                                    </div>
                                </ScrollPanel-prime>
                            </div>
                        </div>
                        <!-- / Flow Vehicle Specification End -->

                        <div class="step-container seven-flow flow-information flow-information-motor"
                            v-if="user_motor_journey.step == 'vehicle-details'">
                            <div class="flow-step-container border-0 p-0 height-auto ">
                                <h2 class="flow-title">Vehicle Details</h2>

                                <div class="col-12 md:col-12 lg:col-12 getQuote-form-area">
                                    <div class="getQuote-form-area-inner grid">
                                        <div class="col-12 md:col-6 lg:col-6 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Vehcile Type</label>
                                                <dropdown-prime filter v-model="VehcileType"
                                                    :options="VehcileTypeOptions" optionLabel="name" placeholder=""
                                                    class="w-full md:w-14rem" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Chassis Number</label>
                                                <inputText-prime class="form-input-fields" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Trafic Code</label>
                                                <inputText-prime class="form-input-fields" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Registration Type</label>
                                                <dropdown-prime filter v-model="RegistrationType"
                                                    :options="RegistrationTypeOptions" optionLabel="name" placeholder=""
                                                    class="w-full md:w-14rem" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Date of First Registration</label>
                                                <calendar-prime v-model="RegistrationFirstDate" showIcon iconDisplay="input" dateFormat="dd MM yy" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Purchaned From Dealer</label>
                                                <dropdown-prime v-model="PurchanedDealer"
                                                    :options="PurchanedDealerOptions" optionLabel="name" placeholder=""
                                                    class="w-full md:w-14rem" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Tanker Capacity</label>
                                                <dropdown-prime filter v-model="TankerCapacity"
                                                    :options="TankerCapacityOption" optionLabel="name" placeholder=""
                                                    class="w-full md:w-14rem" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button-prime class="flow-continuebtn" label="Continue" @click="set_value(1, 'vehicle-details')" />
                        </div>

                        <!-- Flow Car Value Start v-if="step == 'flow-eight'" -->
                        <div class="step-container eight-flow flow-no-border height-auto"
                            v-if="user_motor_journey.step == 'vehicle_value'">
                            <div class="flow-step-container border-0 p-0">
                                <h2 class="flow-title">Select the value of your car</h2>

                                <div class="journey-loaders-list journey-loaders-value" v-if="vehicle_value_range == null">
                                    <Skeleton-prime class="mb-2" style="height: 120px !important;"></Skeleton-prime>
                                    <Skeleton-prime class="mb-2"></Skeleton-prime>
                                    <Skeleton-prime class="mb-2"></Skeleton-prime>
                                </div>

                                <div class="insurance-type-container" v-if="vehicle_value_range!= null">
                                    <div class="car-value-type-box">
                                        <div class="value-of-car-box">
                                            <label>Value of vehicle</label>

                                            <div class="vehile-price">
                                                <h1>{{ user_motor_journey.vehicle_value == null ?
                                                    number_with_commas(vehicle_value_range.min) :
                                                    number_with_commas(user_motor_journey.vehicle_value)}}</h1>
                                                <p>AED</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="car-value-slider">
                                        <slider-prime v-model="user_motor_journey.vehicle_value"
                                            :max="vehicle_value_range.max" :min="vehicle_value_range.min"
                                            class="w-14rem" />

                                        <div class="car-value-bottom">
                                            <p>AED {{ number_with_commas(vehicle_value_range.min) }}</p>
                                            <p>AED {{ number_with_commas(vehicle_value_range.max) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button-prime class="flow-continuebtn" label="Continue" @click="set_step('city')" />
                        </div>
                        <!-- / Flow Car Value end -->

                        <!-- Flow City Start -->
                        <div class="step-container fourth-flow" v-if="user_motor_journey.step == 'city'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Select your Emirates</h2>

                                <!-- <div class="search-box-field">
                                    <IconField-prime iconPosition="right">
                                        <InputIcon-prime class="pi pi-search"> </InputIcon-prime>
                                        <inputText-prime @keyup="searchCities" v-model="searchcity" placeholder="Search your city" />
                                    </IconField-prime>
                                </div> -->

                                <ScrollPanel-prime class="content-scroll-panel mt-2">
                                    <div class="select-list-item" v-for="item in uae_cities" :key="item"
                                        @click="set_value(item, 'city')">
                                        <div class="select-list-item-box mb-0">
                                            <h2 class="select-list-item-name">{{ item.name }}</h2>
                                        </div>
                                    </div>
                                </ScrollPanel-prime>
                            </div>
                        </div>
                        <!-- / Flow City End -->

                        <!-- Flow Insurance type Start -->
                        <div class="step-container five-flow flow-no-border height-auto flow-insurance-type-required"
                            v-if="user_motor_journey.step == 'insurance_type'">
                            <div class="flow-step-container border-0 p-0">
                                <h2 class="flow-title">Select the insurance type Required</h2>
                                <div class="insurance-type-container">
                                    <div class="insurance-type-box"
                                        v-for="item in insurance_types" :key="item"
                                        @click="set_value(item, 'insurance_type')">
                                        <div class="insurance-type-icon">
                                            <img :src="item.icon">
                                        </div>
                                        <div class="insurance-type-info">
                                            <h2 class="m-0">{{ item.name }}</h2>
                                            <p class="m-0">{{ item.description }}</p>
                                            <a href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- / Flow Insurance type End -->

                        <!-- Flow Insurance Date Start -->
                        <div class="step-container six-flow flow-insurance-date motor-flow-insurance-date flow-no-border"
                            v-if="user_motor_journey.step == 'insurance_start_date'">
                            <div class="flow-step-container border-0 p-0 height-auto ">
                                <h2 class="flow-title">When do you want to start your insurance</h2>
                                <div class="col-12 md:col-12 lg:col-12 getQuote-form-area">
                                    <div class="getQuote-form-area-inner grid m-0">
                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main p-0">
                                            <span class="p-input-icon-left">
                                                <calendar-prime v-model="user_motor_journey.insurance_start_date"
                                                    showIcon iconDisplay="input"
                                                    :minDate="user_motor_journey.insurance_start_date"
                                                    dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main p-0 mb-0">
                                            <span class="p-input-icon-left">
                                                <dropdown-prime :showClear="true"
                                                    v-model="user_motor_journey.current_insurance_company"
                                                    :options="companies" optionLabel="name"
                                                    placeholder="Select your current insurance company"
                                                    class="w-full md:w-14rem" filter />
                                            </span>
                                        </div>
                                    </div>
                                    <div class="getQuote-form-area-inner grid ml-0 mr-0 mt-5"
                                        v-if="user_motor_journey.current_insurance_company!=null">
                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main mb-0">
                                            <span class="p-input-icon-left check-box">
                                                <checkbox-prime
                                                    v-model="user_motor_journey.current_insurance_is_comprehensive"
                                                    :binary="true" inputId="insuranceStatus1" />
                                                <label for="insuranceStatus1" class="ml-2"> My current insurance is
                                                    Comprehensive </label>
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left check-box">
                                                <checkbox-prime v-model="user_motor_journey.current_insurance_is_active"
                                                    :binary="true" inputId="insuranceStatus2" />
                                                <label for="insuranceStatus2" class="ml-2"> My current insurance is
                                                    still active. ( Not Expired ) </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button-prime class="flow-continuebtn" label="Continue" @click="set_step('user_detail')" />
                        </div>
                        <!-- / Flow Insurance Date End -->

                        <!-- Flow Insurance type Start -->
                        <div class="step-container seven-flow flow-information flow-information-motor"
                            v-if="user_motor_journey.step == 'user_detail'">
                            <div class="flow-step-container border-0 p-0 height-auto ">
                                <h2 class="flow-title">Fill in the information</h2>

                                <div class="col-12 md:col-12 lg:col-12 getQuote-form-area">
                                    <div class="getQuote-form-area-inner grid">
                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Full Name</label>
                                                <inputText-prime v-model="user_motor_journey.customer.full_name"
                                                    class="form-input-fields" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Mobile number</label>
                                                <vue-tel-input v-model="user_motor_journey.customer.phone_number"
                                                    mode="auto" :inputOptions="inputOptions"
                                                    :dropdownOptions="dropdownOptions" validCharactersOnly
                                                    :defaultCountry="default_country" @on-input="onInput"
                                                    @validate="valid"></vue-tel-input>
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Email</label>
                                                <inputText-prime v-model="user_motor_journey.customer.email"
                                                    class="form-input-fields" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Year of Birth</label>
                                                <calendar-prime v-model="user_motor_journey.customer.date_of_birth"
                                                    showIcon iconDisplay="input"
                                                    :maxDate="user_motor_journey.customer.date_of_birth"
                                                    dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Nationality</label>
                                                <dropdown-prime filter v-model="user_motor_journey.customer.nationality"
                                                    :options="nationalities" optionLabel="name" placeholder=""
                                                    class="w-full md:w-14rem" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Driving
                                                    Experience</label>
                                                <dropdown-prime filter
                                                    v-model="user_motor_journey.customer.driving_experience"
                                                    :options="driving_experiences" optionLabel="name" placeholder=""
                                                    class="w-full md:w-14rem" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Claim History</label>
                                                <dropdown-prime filter
                                                    v-model="user_motor_journey.customer.claim_history"
                                                    :options="claim_histories" optionLabel="name" placeholder=""
                                                    class="w-full md:w-14rem" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button-prime class="flow-continuebtn" :loading="loading" label="Continue"
                                @click="save_and_generate_quotation" />

                        </div>
                        <!-- / Flow Insurance type End -->
                    </div>
                </div>
            </div>
        </div>
    </main>

    <toast-prime />

    <getAgentHelp />

    <dialog-prime v-model:visible="alert_modal" header="ALERT" :style="{ width: '25rem' }" position="BottomRight"
        :modal="true" :draggable="false" class="eligible-popup">
        <span class="p-text-secondary block mb-5">You are not eligible for online policy purchase, Kindly contact us
            (whatsapp, email, call) to get detailed pricing.</span>
        <div class="flex justify-content-end gap-2">
            <a :href="'mailto:'+company_email"><button-prime class="support-chatnow-btn" label="Email"
                    icon="pi pi-envelope" /></a>
            <a :href="'https://wa.me/'+company_whatsapp_number"><button-prime class="support-chatnow-btn"
                    label="Whatsapp" icon="pi pi-whatsapp" /></a>
            <a :href="'tel:'+company_phone_number"><button-prime class="support-chatnow-btn" label="Call"
                    icon="pi pi-phone" /></a>
        </div>
    </dialog-prime>

</template>


<script>
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

import getAgentHelp from '../getAgentHelp.vue'

export default {
    components: {
        getAgentHelp,
    },
    data() {
        return {
            alert_modal : false,
            makes : null,
            models : null,
            years : null,
            specifications : null,
            // vehicle_value_range : {
            //     min : 20000,
            //     max : 500000
            // },
            vehicle_value_range : null,
            uae_cities : [],
            insurance_types : [],
            companies : [],
            nationalities : [],
            driving_experiences : [],
            claim_histories : [],
            user_motor_journey : {
                quotation_id : null,
                quotation_number : null,
                step: 'make',
                make : null,
                model : null,
                year : null,
                specification : null,
                vehicle_value : null,
                uae_city : null,
                insurance_type : null,
                insurance_start_date : new Date,
                insurance_start_date_label : null,
                insurance_start_date_db_formatted : null,
                current_insurance_company : null,
                current_insurance_is_comprehensive : false,
                current_insurance_is_active : false,
                status : 'lead',
                customer : {
                    full_name : null,
                    email : null,
                    phone_number : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
                    date_of_birth : new Date(new Date().setTime(new Date().valueOf() - 18 * 365 * 24 * 60 * 60 * 1000)),
                    db_formatted_date_of_birth : null,
                    nationality : null,
                    driving_experience : null,
                    claim_history : null
                },
                progress_bar_percentage : 0,
                progress_bar_per_question : 12.5,
                progress_obtained_mark : 0,
                total_questions : 8,
                question_number : 1,
            },
            searchmake : null,
            searchmodel : null,
            searchcity : null,
            is_phone_number_valid : false,
            default_country : 'AE',
            loading : false,
            language_options : ['EN', 'العربية'],
            selected_language : 'EN',
            inputOptions : { 
                showDialCode : true,
                placeholder : 'Enter Phone Number'
            },
            dropdownOptions : {
                showDialCodeInList : true,
                showFlags : true,
                showSearchBox : true,
            }
        }
    },
    created() {
        if (localStorage.getItem("mebrokers.customer.phone.number.verified") != null && localStorage.getItem("mebrokers.customer.phone.number") != null) {
        var parse_data = JSON.parse(localStorage.getItem("mebrokers.customer.phone.number.verified"));
        if ((parse_data.mobile && localStorage.getItem("mebrokers.customer.phone.number")) && parse_data.verified == 'yes') {
            console.log('do nothing');
        }
        } else {
            window.location.href="/motor";
        }
    },
    mounted() {
        this.get_makes();
        this.get_uae_cities();
        this.get_all_data();
        this.get_companies();

        if (this.$route.query.quotation_id != undefined && this.$route.query.quotation_id != '') {
            this.get_quotation_details(this.$route.query.quotation_id);
        } else if (localStorage.getItem('mebrokers.customer.motor.journey') != null) {
            try {
                this.user_motor_journey = JSON.parse(localStorage.getItem('mebrokers.customer.motor.journey'));
                this.user_motor_journey.customer.date_of_birth = new Date(this.user_motor_journey.customer.date_of_birth);
                this.user_motor_journey.insurance_start_date = new Date(this.user_motor_journey.insurance_start_date);
                if (this.user_motor_journey.step == 'make') {
                    this.user_motor_journey.question_number = 1;
                    this.calculate_percentage();
                }
                if (this.user_motor_journey.step == 'model') {
                    this.user_motor_journey.question_number = 2;
                    this.get_models();
                    this.calculate_percentage();
                }
                if (this.user_motor_journey.step == 'year') {
                    this.user_motor_journey.question_number = 3;
                    this.get_years();
                    this.calculate_percentage();
                }
                if (this.user_motor_journey.step == 'specification') {
                    this.user_motor_journey.question_number = 4;
                    this.get_specifications();
                    this.calculate_percentage();
                }
                if (this.user_motor_journey.step == 'vehicle-details') {
                    this.user_motor_journey.question_number = 5;
                }
                if (this.user_motor_journey.step == 'city') {
                    this.user_motor_journey.question_number = 6;
                    this.calculate_percentage();
                }
                if (this.user_motor_journey.step == 'insurance_type') {
                    this.user_motor_journey.question_number = 7;
                    this.calculate_percentage();
                }
                if (this.user_motor_journey.step == 'insurance_start_date') {
                    this.user_motor_journey.question_number = 8;
                    this.calculate_percentage();
                }
                // if (this.user_motor_journey.step == 'user_detail') {
                    
                // }
                    
            } catch(err) {
                localStorage.removeItem('mebrokers.customer.motor.journey');
                localStorage.removeItem('mebrokers.customer.motor.plans.comparison');
            }
        }
          
        $.expr[":"].contains = $.expr.createPseudo(function(arg) {
            return function( elem ) {
                return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
            };
        });
       
    },
    methods: {
        get_vehicle_prices : function (callback) {
            this.vehicle_value_range = null;
            axios.get(this.api_url+'motor/get_vehicle_prices', {
            params : {
                make_code : this.user_motor_journey.make.code,
                model_code : this.user_motor_journey.model.code,
                year_code : this.user_motor_journey.year.code,
                specification_code : this.user_motor_journey.specification.code
            }
            }).then((response) => {
                setTimeout(() => {
                    this.vehicle_value_range = {};
                    this.vehicle_value_range.min = response.data.data.min_price;
                    this.vehicle_value_range.max = response.data.data.max_price;
                    this.user_motor_journey.vehicle_value = this.vehicle_value_range.min;
                }, "500");
                if (callback) {
                    callback();
                }
            });     
        },
        get_quotation_details : function(quotation_id) {
            axios.get(this.api_url+'motor/get_quotation_details', {
                params : {
                    quotation_id : quotation_id
                }
            }).then((response) => {
                var quotation = response.data.data.quotation;
                localStorage.removeItem('mebrokers.customer.motor.journey');
                this.user_motor_journey.quotation_id = quotation.unique_id;
                this.user_motor_journey.quotation_number = quotation.quotation_number;
                this.user_motor_journey.step = 'make';
                for (let i = 0; i < this.makes.length; i++) {
                    if (quotation.make_id == this.makes[i].id) {
                        this.user_motor_journey.make = this.makes[i];
                        this.get_models(() => {
                            for (let j = 0; j < this.models.length; j++) {
                                if (quotation.model_id == this.models[j].id) {
                                    this.user_motor_journey.model = this.models[j];
                                    this.get_years(() => {
                                        for (let k = 0; k < this.years.length; k++) {
                                            if (quotation.year_id == this.years[k].id) {
                                                this.user_motor_journey.year = this.years[k];
                                                this.get_specifications(() => {
                                                    for (let l = 0; l < this.specifications.length; l++) {
                                                        if (quotation.specification_id == this.specifications[l].id) {
                                                            this.user_motor_journey.specification = this.specifications[l];
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }
                            }
                        })
                        
                    }
                }
                
                for (let i = 0; i < this.uae_cities.length; i++) {
                    if (quotation.city_id == this.uae_cities[i].id) {
                        this.user_motor_journey.uae_city = this.uae_cities[i];
                    }
                }

                for (let i = 0; i < this.insurance_types.length; i++) {
                    if (quotation.insurance_type_id == this.insurance_types[i].id) {
                        this.user_motor_journey.insurance_type = this.insurance_types[i];
                    }
                }

                for (let i = 0; i < this.companies.length; i++) {
                    if (quotation.current_insurance_company == this.companies[i].id) {
                        this.user_motor_journey.current_insurance_company = this.companies[i];
                    }
                }
                
                for (let i = 0; i < this.driving_experiences.length; i++) {
                    if (quotation.driving_exp_id == this.driving_experiences[i].id) {
                        this.user_motor_journey.customer.driving_experience = this.driving_experiences[i];
                    }
                }

                for (let i = 0; i < this.nationalities.length; i++) {
                    if (quotation.nationality_id == this.nationalities[i].id) {
                        this.user_motor_journey.customer.nationality = this.nationalities[i];
                    }
                }

                for (let i = 0; i < this.claim_histories.length; i++) {
                    if (quotation.claim_history_id == this.claim_histories[i].id) {
                        this.user_motor_journey.customer.claim_history = this.claim_histories[i];
                    }
                }

                this.user_motor_journey.customer.full_name = quotation.full_name;
                this.user_motor_journey.customer.email = quotation.email;
                this.user_motor_journey.customer.phone_number = quotation.mobile;

                this.user_motor_journey.vehicle_value = quotation.vehicle_value;
                this.user_motor_journey.customer.date_of_birth = new Date(quotation.date_of_birth);
                this.user_motor_journey.insurance_start_date = new Date(quotation.start_date);
                this.user_motor_journey.insurance_start_date_label = moment(quotation.start_date).format("DD MMMM YYYY");
                this.user_motor_journey.insurance_start_date_db_formatted = moment(quotation.start_date).format("YYYY-MM-DD");
                this.user_motor_journey.current_insurance_is_comprehensive = false;
                this.user_motor_journey.current_insurance_is_active = (quotation.current_insurance_company_policy_validity == 'Yes') ? true : false;
                this.user_motor_journey.status = quotation.status;
                
            });
        },
        calculate_percentage : function() {
            this.user_motor_journey.progress_obtained_mark = this.user_motor_journey.progress_bar_per_question*this.user_motor_journey.question_number;
            this.user_motor_journey.progress_bar_percentage = Math.round(this.user_motor_journey.progress_obtained_mark/(this.user_motor_journey.total_questions*this.user_motor_journey.progress_bar_per_question)*100)
        },
        save_and_generate_quotation : function() {
            
            if (this.user_motor_journey.customer.full_name == null || this.user_motor_journey.customer.full_name == '') {
                this.$toast.add({ severity: 'error', detail: 'Full name is required!', life: 3000 });
                return false; 
            }

            if (!this.is_phone_number_valid) {
                this.$toast.add({ severity: 'error', detail: 'Invalid Phone number!', life: 3000 });
                return false; 
            }

            if (this.user_motor_journey.customer.email == null || this.user_motor_journey.customer.email == '') {
                this.$toast.add({ severity: 'error', detail: 'Email is required!', life: 3000 });
                return false; 
            }

            if (!this.validate_email(this.user_motor_journey.customer.email)) {
                this.$toast.add({ severity: 'error', detail: 'Incorrect email!', life: 3000 });
                return false; 
            }

            if (this.user_motor_journey.customer.nationality == null) {
                this.$toast.add({ severity: 'error', detail: 'Nationality is required!', life: 3000 });
                return false; 
            }

            if (this.user_motor_journey.customer.driving_experience == null) {
                this.$toast.add({ severity: 'error', detail: 'Driving experience option is required!', life: 3000 });
                return false; 
            }

            if (this.user_motor_journey.customer.claim_history == null) {
                this.$toast.add({ severity: 'error', detail: 'Claim history option is required!', life: 3000 });
                return false; 
            }
            
            this.loading = true;

            if (localStorage.getItem('mebrokers.customer.motor.journey') != null) {
                this.user_motor_journey.quotation_id = JSON.parse(localStorage.getItem('mebrokers.customer.motor.journey')).quotation_id;
            }

            axios.get(this.api_url+'motor/save_quotation', {
                params : {
                    customer : {
                        full_name : this.user_motor_journey.customer.full_name,
                        email : this.user_motor_journey.customer.email,
                        mobile : this.user_motor_journey.customer.phone_number,
                        date_of_birth : moment(this.user_motor_journey.customer.date_of_birth).format("YYYY-MM-DD"),
                        nationality : this.user_motor_journey.customer.nationality.id,
                        driving_experience : this.user_motor_journey.customer.driving_experience.id,
                        claim_history : this.user_motor_journey.customer.claim_history.id
                    },
                    make : this.user_motor_journey.make.id,
                    model : this.user_motor_journey.model.id,
                    year : this.user_motor_journey.year.id,
                    specification : this.user_motor_journey.specification.id,
                    vehicle_value : this.user_motor_journey.vehicle_value,
                    uae_city : this.user_motor_journey.uae_city.id,
                    insurance_start_date : this.user_motor_journey.insurance_start_date_db_formatted,
                    insurance_type : this.user_motor_journey.insurance_type.id,
                    current_insurance_company : this.user_motor_journey.current_insurance_company != null ? this.user_motor_journey.current_insurance_company.id : null,
                    current_insurance_company_policy_validity : this.user_motor_journey.current_insurance_is_active,
                    quotation_id : this.user_motor_journey.quotation_id,
                    status : this.user_motor_journey.status
                }
            }).then((response) => {
                this.user_motor_journey.quotation_id = response.data.data.quotation_id;
                this.user_motor_journey.quotation_number = response.data.data.quotation_number;
                localStorage.setItem('mebrokers.customer.motor.journey', JSON.stringify(this.user_motor_journey))
                localStorage.setItem('mebrokers.customer.phone.number', this.user_motor_journey.customer.phone_number);
                this.loading = false;
                if (this.$route.query.is_from != undefined && this.$route.query.is_from == 'admin') {
                    this.$toast.add({ severity: 'success', detail: 'Changes saved!', life: 3000 });
                } else {
                    this.$router.push({
                        path : '/motor/plan-listing/'+this.user_motor_journey.quotation_id,
                    });
                }
                
            });
       
        
        },
        onInput : function(phone, obj) {
            if(obj.valid) {
                this.is_phone_number_valid = true;
            } else {
                this.is_phone_number_valid = false;
            }
        },
        valid : function(obj) {
            if (obj.valid != undefined) {
                if(obj.valid) {
                    this.is_phone_number_valid = true;
                } else {
                    this.is_phone_number_valid = false;
                }
            } else {
                this.is_phone_number_valid = false;
            }
        },
        set_step: function (step_next) { 

            if (step_next == 'model') {
                if (this.user_motor_journey.make == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the vehicle brand!', life: 3000 });
                    return false;
                }
                this.user_motor_journey.question_number = 2;
                this.get_models();
            }

            if (step_next == 'year') {
                if (this.user_motor_journey.model == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the vehicle model!', life: 3000 });
                    return false;
                }
                this.user_motor_journey.question_number = 3;
                this.get_years();
            }

            if (step_next == 'specification') {
                if (this.user_motor_journey.year == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the vehicle model year!', life: 3000 });
                    return false;
                }
                this.user_motor_journey.question_number = 4;
                this.get_specifications();
            }

            // if (step_next == 'vehicle-details') {
            //     this.user_motor_journey.question_number = 5;
            // }

            if (step_next == 'vehicle_value') {
                if (this.user_motor_journey.specification == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the vehicle specification!', life: 3000 });
                    return false;
                }
                this.user_motor_journey.question_number = 5;
                this.get_vehicle_prices()
            }

            if (step_next == 'city') {
                this.user_motor_journey.question_number = 6;
            }

            if (step_next == 'insurance_type') {
                if (this.user_motor_journey.uae_city == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the city!', life: 3000 });
                    return false;
                }
                this.user_motor_journey.question_number = 7;
            }

            if (step_next == 'insurance_start_date') {
                if (this.user_motor_journey.insurance_type == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the Insurance Type!', life: 3000 });
                    return false;
                }
                this.user_motor_journey.question_number = 8;
            }

            if (step_next == 'user_detail') {
                if (this.user_motor_journey.current_insurance_company == null) {
                    this.alert_modal = true;
                    return false;
                }
                this.user_motor_journey.insurance_start_date_label = moment(this.user_motor_journey.insurance_start_date).format("DD MMMM YYYY");
                this.user_motor_journey.insurance_start_date_db_formatted = moment(this.user_motor_journey.insurance_start_date).format("YYYY-MM-DD");
                
            }
            
            this.user_motor_journey.progress_obtained_mark += 12.5;
        
            this.user_motor_journey.progress_bar_percentage = Math.round(this.user_motor_journey.progress_obtained_mark/(this.user_motor_journey.total_questions*this.user_motor_journey.progress_bar_per_question)*100)
            this.user_motor_journey.step = step_next;
        },

        back: function () {
            if (this.user_motor_journey.step == 'user_detail') {
                this.user_motor_journey.step = 'insurance_start_date';
                this.user_motor_journey.question_number = 8;
            } else if (this.user_motor_journey.step == 'insurance_start_date') {
                this.user_motor_journey.step = 'insurance_type';
                this.user_motor_journey.question_number = 7;
            } else if (this.user_motor_journey.step == 'insurance_type') {
                this.user_motor_journey.step = 'city';
                this.user_motor_journey.question_number = 6;
            } else if (this.user_motor_journey.step == 'city') {
                this.user_motor_journey.step = 'vehicle_value';
                this.get_vehicle_prices();
                this.user_motor_journey.question_number = 5;
            }  else if (this.user_motor_journey.step == 'vehicle_value') {
                this.user_motor_journey.step = 'specification';
                this.get_specifications();
                this.user_motor_journey.question_number = 4;
            }  
            // else if (this.user_motor_journey.step == 'vehicle-details') {
            //     this.user_motor_journey.step = 'specification';
            //     this.get_specifications();
            //     this.user_motor_journey.question_number = 4;
            // }  
            else if (this.user_motor_journey.step == 'specification') {
                this.user_motor_journey.step = 'year';
                this.get_years();
                this.user_motor_journey.question_number = 3;
            } else if (this.user_motor_journey.step == 'year') {
                this.user_motor_journey.step = 'model';
                this.get_models();
                this.user_motor_journey.question_number = 2;
            } else if (this.user_motor_journey.step == 'model') {
                this.user_motor_journey.step = 'make';
                this.user_motor_journey.question_number = 1;
            } else if (this.user_motor_journey.step == 'make') {
                this.$router.push({
                    name : 'motor',
                });
            }

            this.user_motor_journey.progress_obtained_mark -= 12.5;
            this.user_motor_journey.progress_bar_percentage = Math.round(this.user_motor_journey.progress_obtained_mark/(this.user_motor_journey.total_questions*this.user_motor_journey.progress_bar_per_question)*100)
        
        },
        set_value : function(item, type) {
            if (type == 'make') {
                this.user_motor_journey.make = item;
                this.set_step('model');
            }
            if (type == 'model') {
                this.user_motor_journey.model = item;
                this.set_step('year');
            }
            if (type == 'year') {
                this.user_motor_journey.year = item;
                this.set_step('specification');
            }
            if (type == 'specification') {
                this.user_motor_journey.specification = item;
                this.set_step('vehicle_value');
            }
            if (type == 'vehicle-details') {
                this.set_step('vehicle_value');
            }
            if (type == 'city') {
                this.user_motor_journey.uae_city = item;
                this.set_step('insurance_type');
            }
            if (type == 'insurance_type') {
                this.user_motor_journey.insurance_type = item;
                this.set_step('insurance_start_date');
            }
        },
        get_makes : function() {
            this.makes = null;
            axios.get(this.api_url+'motor/get_makes').then((response) => {
                setTimeout(() => {
                    this.makes = response.data.data;
                }, "500");
            });
        },
        get_models : function(callback) {
            this.models = null;
            axios.get(this.api_url+'motor/get_make_models', {
            params : {
                make_code : this.user_motor_journey.make.code
            }
            }).then((response) => {
                setTimeout(() => {
                    this.models = response.data.data;
                }, "500");
                if (callback) {
                    callback();
                }
            });
        },
        get_years : function(callback) {
            this.years = null;
            axios.get(this.api_url+'motor/get_make_model_years', {
            params : {
                make_code : this.user_motor_journey.make.code,
                model_code : this.user_motor_journey.model.code
            }
            }).then((response) => {
                setTimeout(() => {
                    this.years = response.data.data;
                }, "500");
                if (callback) {
                    callback();
                }
            });
        },
        get_specifications : function(callback) {
            this.specifications = null;
            axios.get(this.api_url+'motor/get_make_model_year_specifications', {
            params : {
                make_code : this.user_motor_journey.make.code,
                model_code : this.user_motor_journey.model.code,
                year_code : this.user_motor_journey.year.code
            }
            }).then((response) => {
                setTimeout(() => {
                    this.specifications = response.data.data;
                }, "500");
                if (callback) {
                    callback();
                }
            });
        },
        get_uae_cities : function() {
            axios.get(this.api_url+'motor/motor_emirates').then((response) => {
                this.uae_cities = response.data.data;
            });
        },
        searchMakes : function() {
            $('.makes-list').removeClass('hidden');
            $('.makes-list:not(:contains('+ this.searchmake +'))').addClass('hidden');
        },
        searchModels : function() {
            $('.models-list').removeClass('hidden');
            $('.models-list:not(:contains('+ this.searchmodel +'))').addClass('hidden');
        },
        searchCities : function() {
            $('.cities-list').removeClass('hidden');
            $('.cities-list:not(:contains('+ this.searchcity +'))').addClass('hidden');
        },
        stop_writing_on_date : function(eve) {
            eve.preventDefault();
        },
        get_all_data : function() {
            axios.get(this.api_url+'motor/get_all_data').then((response) => {
                this.driving_experiences = response.data.data.driving_experiences;
                this.claim_histories = response.data.data.claim_histories;
                this.nationalities = response.data.data.nationalities;
                this.insurance_types = response.data.data.insurance_types;
                
            });
        },
        get_companies : function() {
            axios.get(this.api_url+'get_all_companies').then((response) => {
                this.companies = response.data.data;
            });
        },
        validate_email : function (email) {
            // eslint-disable-next-line
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        number_with_commas : function (x) {
            // eslint-disable-next-line
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}

</script>